<template>
    <div class="pt-[15vh]">
        <div v-if="loading" style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center">
            <LoadingComponent />
        </div>
        <div v-else>
            <div class="w-full bg-[#fff] my-[2%] px-[6%]">
                <h1 class="text-[24px] font-[600] text-left mx-0 my-2">{{ jobPosition.title }}</h1>
            </div>
            <div class="bg-[#F8F8FA] py-10">
                <div class="w-full flex justify-between">
                    <div class="w-1/2 flex justify-center">
                        <div class="bg-[#fff] px-4 py-4 text-left rounded-md w-[75%] h-[fit-content]">
                            <h2 class="text-[14px] font-[700] text-[#C0C3CC]">Assessment Details</h2>
                            <hr class="h-[2px] bg-[#F5F8FF] my-4" />
                            <div class="flex items-center justify-between w-full">
                                <h2 class="font-[700] text-[#C0C3CC] text-[14px]">Author</h2>
                                <h2 class="font-[600] text-[14px]">GO PLATFORM</h2>
                            </div>
                            <hr class="h-[2px] bg-[#F5F8FF] my-4" />
                            <div class="flex items-center justify-between w-full">
                                <h2 class="font-[700] text-[#C0C3CC] text-[14px]">Questions</h2>
                                <h2 class="font-[600] text-[14px]">{{ this.totalQst }}</h2>
                            </div>
                            <hr class="h-[2px] bg-[#F5F8FF] my-4" />
                            <div class="flex items-center justify-between w-full">
                                <h2 class="font-[700] text-[#C0C3CC] text-[14px]">Skills</h2>
                                <h2 class="font-[600] text-[14px]">{{ this.jobPosition.assessments?.length }}</h2>
                            </div>
                            <div class="flex flex-wrap gap-2 my-4" style="margin-bottom: 20px; padding: 0px 20px">
                                <span
                                    v-for="(assessment, index) in this.jobPosition.assessments"
                                    :key="index"
                                    href="/"
                                    class=""
                                    style="
                                        background-color: rgb(236, 238, 238);
                                        color: rgb(44, 47, 48);
                                        border-radius: 20px;
                                        padding: 10px 17px;
                                        font-size: 12px;
                                        width: fit-content;
                                        height: fit-content;
                                        font-weight: bold;
                                    "
                                    >{{ assessment.name }}</span
                                >
                            </div>
                            <hr class="h-[2px] bg-[#F5F8FF] my-4" />
                            <div class="w-full my-4">
                                <!-- to="/register" -->
                                <router-link
                                    to="/register"
                                    class="text-base flex items-center justify-center text-[14px] font-[700] mr-4 py-3 my-4 px-4 border border-slate-300 text-white whitespace-nowrap w-full bg-[#3361FF] hover:bg-[#fff] hover:text-[#5d5fef] hover:border-NeonBlue duration-300"
                                >
                                    <!-- style="background-color: #0c58b6; color: white" -->
                                    Use Assessment Template
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/2">
                        <div class="rounded-md w-[75%] text-left">
                            <h2 class="text-NeonBlue font-[600] text-[16px] mb-[16px]">Assessment Details</h2>
                            <p class="text-[16px] font-[400] text-[#645E60]">
                                {{ this.jobPosition.description }}
                            </p>
                            <div class="flex flex-col gap-8 mt-[3%]">
                                <div
                                    class="bg-[#fff] rounded-md py-6 px-8 h-[fit-content] duration-500 shadow-card hover:shadow-none"
                                    v-for="(assessment, index) in jobPosition.assessments"
                                    :key="index"
                                    @click="() => (assessment.showDesc = !assessment.showDesc)"
                                >
                                    <h1 class="text-[16px] font-[510] flex items-center">
                                        <font-awesome-icon :icon="['fas', 'circle-plus']" class="mr-4 text-[25px] text-[#3361FF]" />
                                        {{ assessment.name }}
                                    </h1>
                                    <Transition name="slide-fade">
                                        <p v-if="assessment.showDesc" class="my-6 text-[14px]">
                                            {{ assessment.description }}
                                        </p>
                                    </Transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-around mx-[20%] py-10">
                <div class="flex flex-col items-center">
                    <img src="@/assets/product-hunt.svg" style="width: 64px; padding-right: 6px; border-right: 1px solid white" />
                    <div class="flex gap-3 mt-5">
                        <i style="color: gold; font-size: 20px; padding-right: 2px" class="fa-solid fa-star" v-for="index in 5" :key="index"></i>
                    </div>
                </div>
                <div class="flex flex-col items-center">
                    <img src="@/assets/Google__G__logo.svg.png" style="width: 55px; border-right: 1px solid white" />
                    <div class="flex gap-3 mt-5">
                        <i style="color: gold; font-size: 20px; padding-right: 2px" class="fa-solid fa-star" v-for="index in 5" :key="index"></i>
                    </div>
                </div>
            </div>
            <div class="w-[96%] mx-auto">
                <LeadingSkills />
            </div>
            <SampleQst :title="jobPosition.title" :assessments="jobPosition.assessments" />
            <div class="px-[6%] py-[3%] flex justify-between items-center bg-gradient-to-r from-[#5d5fef] to-[#3db5e6]">
                <div class="text-left text-[#fff]">
                    <h1 class="text-[46px] font-[700]">Never make another bad hire</h1>
                    <h1 class="text-[24px] font-[500]">Traditional job interviews don't do the job</h1>
                </div>
                <router-link
                    to="/request-service"
                    class="text-[#fff] w-[fit-content] z-3 font-[700] my-[5%] bg-[#3361FF] px-4 py-4 rounded-md border border-[#3361FF] hover:bg-[#fff] hover:text-[#3361FF] duration-300 cursor-pointer duration-300"
                >
                    Start For Free
                </router-link>
            </div>
            <TransformYourHire />
            <CandidateReportCard />
        </div>
    </div>
</template>

<script>
import LeadingSkills from "@/components/LeadingSkills.vue";
import SampleQst from "@/components/SampleQst.vue";
import TransformYourHire from "@/components/TransformYourHire.vue";
import CandidateReportCard from "@/components/CandidateReportCard.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";

export default {
    name: "JobPosPreview",
    components: {
        LeadingSkills,
        SampleQst,
        TransformYourHire,
        CandidateReportCard,
        LoadingComponent,
    },
    data() {
        return {
            jobPosition: {},
            loading: true,
            totalQst: 0,
        };
    },
    mounted() {
        this.jobPosition = this.$route.query.jobPosition;
        axios
            //https://server.go-platform.com/
            .get(`https://server.go-platform.com/jobsPages/preview`, {
                params: {
                    jobTitle: this.jobPosition,
                },
            })
            .then((res) => {
                //alert(res.data);
                this.loading = false;
                this.totalQst = res.data.totalQst;
                this.jobPosition = res.data.jobPosition;
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>
