<template>
    <HeroSection />
    <ExpertValidation />
    <ContinuousImprovement />
    <SkillsOverview />
</template>

<script>
// import NavBar from "@/components/sciencePage/NavBar.vue";
import HeroSection from "@/components/sciencePage/Hero.vue";
import ExpertValidation from "@/components/sciencePage/ExpertValidation.vue";
import ContinuousImprovement from "@/components/sciencePage/ContinuousImprovement.vue";
import SkillsOverview from "@/components/sciencePage/SkillsOverview.vue";
//   import Footer from "@/components/sciencePage/Footer.vue";
export default {
    name: "App",
    components: {
        HeroSection,
        ExpertValidation,
        ContinuousImprovement,
        SkillsOverview,
        //   Footer,
    },
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.1/css/all.min.css");

section {
    padding: 50px 100px;
}

.main-button {
    position: relative;
    display: inline-block;
    padding: 8px 28px;
    color: #ffffff;
    background-color: #2196f3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    overflow: hidden;
}

.main-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 60px;
    height: 100%;
    background-color: #ffffff34;
    animation: moveRightWithShape 2s infinite;
    clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
}

@keyframes moveRightWithShape {
    to {
        left: 100%;
    }
}

.cta-button {
    display: inline-block;
    padding: 8px 28px;
    color: #ffffff;
    background-color: #2196f3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    overflow: hidden;
}

@media (max-width: 1023px) {
    section {
        padding: 25px 50px;
    }
}
</style>
