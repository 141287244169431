<template>
    <div class="postJobWrapper">
        <div class="exit_zone" @click="togglePostJob"></div>
        <div>
            <div class="form_section">
                <div class="w-full pl-1 pr-1 flex justify-between">
                    <span id="job">{{ $t("Invited recruiter") }}</span>
                </div>
                <form action="POST" @submit.prevent ref="form2">
                    <!-- ... your form content for section 3 ... -->
                    <!-- <div class="input_group2"> -->
                    <div class="input_group">
                        <input type="text" id="first_name" v-model="recruiter.first_name" required name="first_name" />
                        <label
                            class="required floating_label"
                            :class="{
                                active: recruiter.first_name,
                            }"
                            for="first_name"
                        >
                            <img loading="lazy" decoding="async" src="@/assets/Images/icons/user_icon.svg" alt="" />
                            {{ $t("First Name") }}</label
                        >
                        <span v-if="requiredFields.first_name" class="err_msg">{{ requiredFields.first_name }}</span>
                    </div>
                    <div class="input_group">
                        <input type="text" id="last_name" name="last_name" required v-model="recruiter.last_name" />
                        <label class="required floating_label" :class="{ active: recruiter.last_name }" for="last_name"
                            ><img loading="lazy" decoding="async" src="@/assets/Images/icons/user_icon.svg" alt="" />{{ $t("Last name") }}</label
                        >
                        <span v-if="requiredFields.last_name" class="err_msg">{{ requiredFields.last_name }}</span>
                    </div>
                    <!-- </div> -->
                    <!-- <div class="input_group2"> -->
                    <div class="input_group">
                        <input type="email" id="recruiter_email" name="recruiter_email" required v-model="recruiter.email" disabled />
                        <label class="required floating_label" :class="{ active: recruiter.email }" for="recruiter_email"
                            ><img loading="lazy" decoding="async" src="@/assets/Images/icons/email.svg" alt="" />{{ "Email" }}</label
                        >
                        <span v-if="requiredFields.email" class="err_msg">{{ requiredFields.email }}</span>
                    </div>
                    <div class="input_group select">
                        <label class="required select_label" for="phone_number"><img loading="lazy" decoding="async" src="@/assets/Images/icons/icon_Phone.svg" alt="" /></label>
                        <vue-tel-input @input="handlePhoneInput" @country-changed="handleCountryChange" class="phone" name="phone_number"> </vue-tel-input>
                        <span v-if="requiredFields.phone_nbr" class="err_msg">{{ requiredFields.phone_nbr }}</span>
                    </div>
                    <!-- </div> -->
                    <!-- <div class="input_group2"> -->
                    <div class="input_group">
                        <input type="password" id="password" name="recruiter_password" required v-model="recruiter.password" />
                        <label class="required floating_label" :class="{ active: recruiter.password }" for="password"
                            ><img loading="lazy" decoding="async" src="@/assets/Images/icons/lock.svg" alt="" />{{ $t("Password") }}</label
                        >
                        <span v-if="requiredFields.password" class="err_msg">{{ requiredFields.password }}</span>
                    </div>
                    <div class="input_group">
                        <input type="password" id="confirm_password" name="confirm_password" required v-model="recruiter.confirm_password" />
                        <label
                            class="required floating_label"
                            :class="{
                                active: recruiter.confirm_password,
                            }"
                            for="confirm_password"
                            ><img loading="lazy" decoding="async" src="@/assets/Images/icons/lock.svg" alt="" /> {{ $t("Confirm password") }}</label
                        >
                        <span v-if="requiredFields.confirm_password" class="err_msg">{{ requiredFields.confirm_password }}</span>
                    </div>
                    <!-- </div> -->
                </form>
            </div>
            <div class="btn_wrapper">
                <input class="next" type="submit" value="Create" @click="createCoworker" />
            </div>
        </div>
    </div>
</template>

<script>
// import VueMultiselect from "vue-multiselect";
import { useStore } from "@/store/index";
import axios from "axios";
export default {
    name: "InvitedCoworker",
    components: {},
    props: {
        togglePostJob: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            recruiter: {
                first_name: "",
                last_name: "",
                email: "",
                phone_nbr: "",
                password: "",
                confirm_password: "",
                company_name: [],
            },
            requiredFields: {
                first_name: "",
                last_name: "",
                email: "",
                phone_nbr: "",
                password: "",
                confirm_password: "",
            },
        };
    },
    methods: {
        handlePhoneInput(newPhone, newCountry) {
            if (newCountry) {
                this.recruiter.phone_nbr = newCountry?.number;
            }
        },
        validateForm() {
            // Reset requiredFields object
            this.requiredFields = {};

            // Check for required fields
            if (!this.recruiter.first_name) this.requiredFields.first_name = "recruiter name is required";
            if (!this.recruiter.last_name) this.requiredFields.last_name = "last name is required";
            if (!this.recruiter.password) this.requiredFields.password = "password is required";
            if (!this.recruiter.confirm_password) this.requiredFields.confirm_password = "confirm password is required";
            if (!this.recruiter.phone_nbr) this.requiredFields.phone_nbr = "phone number is required";
            // Check if all required fields are filled
            return Object.keys(this.requiredFields).length === 0;
        },
        async createCoworker() {
            if (this.validateForm()) {
                try {
                    axios
                        .post(`https://server.go-platform.com/users/signup`, this.recruiter, {
                            withCredentials: true,
                        })
                        .then(() => {
                            this.Store.userLogged();
                            this.Store.getCompanyCredit();
                            localStorage.setItem("windowShown", "true");
                            this.$router.push("/dashboard");
                            this.recruiter = {
                                name: "",
                                jobTitle: "",
                                seniority: "",
                            };
                        })
                        .catch((error) => console.log({ error }));
                } catch (error) {
                    console.error(error);
                }
            }
        },
    },
    mounted() {
        this.recruiter.company_name.push(this.$route.query.company_name);
        this.recruiter.email = this.$route.query.email;
    },
};
</script>

<style lang="scss" scoped>
.postJobWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    // position: fixed;
    // background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    z-index: 100;

    > :nth-child(2) {
        background: #fff;
        width: 40%;
        padding: 1rem 2rem;
        border-radius: 20px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        position: relative;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            height: 60%;
        }

        .log_in {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: absolute;
            bottom: 15%;
            top: 25%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                color: #5a5a5d;

                > :first-child {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 14px;
                }

                /*>:nth-child(5){
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0 0;
                }*/
                .captcha {
                    padding: 1rem;

                    > :first-child {
                        > :first-child {
                            width: 100% !important;
                        }
                    }
                }
            }

            .sign_up {
                font-weight: 400;
                font-size: 19.96px;
                line-height: 100%;
                color: #bebebf;
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
            }

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                gap: 4rem;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
    }
}

.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.form_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    > :first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2196f3;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: #e5e5ef;
            margin: 0.2rem 0;
        }
    }
}

.err_msg {
    color: #ff6969;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 125%;
    left: 0;
    z-index: 30;
    padding: 0 1% 0 0;
    margin-left: 25px;
    transform: translateY(-50%);
    pointer-events: none;
    background: #fff;
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
    margin: 0.2rem 0;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 25px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 20px;
        width: 100%;
        padding: 8px 1.5rem;
    }

    input {
        height: 3rem;
    }

    textarea {
        resize: none;
    }
}

.btn_wrapper {
    height: 60px;
    width: 70%;
    margin: 0 15%;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 0%;

    .next {
        height: 60px;
        width: 150px;
        background-color: #2196f3;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        padding: 2%;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #fff;
            color: #2196f3;
            border: 1px solid #2196f3;
            cursor: pointer;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    border: 1px solid #ccd7ec;
    border-radius: 20px;
    width: 94%;
    margin: 0 3%;

    > :nth-child(2) {
        width: 90%;
        margin-left: 12%;
        float: right;
        right: 0;
        border: none;

        > :nth-child(2) {
            border: none;
        }
    }

    .select_label {
        position: absolute;
        top: 50%;
        left: 0%;
        z-index: 30;
        margin-left: 25px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
    }

    .err_msg {
        position: absolute;
        top: 150%;
        left: 0;
        z-index: 30;
        padding: 0 1% 0 0;
        margin: 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
    }
}
</style>
