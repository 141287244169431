/* eslint-disable */
<template>
    <ConfirmEmail :isVisible="showConfirm" :toggleConfirmEmail="toggleConfirmEmail" />
    <div class="absolute top-0 left-0 w-full h-full bg-white z-50">
        <ToastNotification :message="message" :isVisible="isVisible" :bgColor="'red'" />
        <div class="register_page">
            <div class="form box relative">
                <!-- <button
                    class="w-5 h-5 border absolute top-3 right-3 border-gray-400 rounded-full bg-white text-gray-400 flex justify-center items-center p-5"
                    @click="
                        () => {
                            this.$router.back();
                        }
                    "
                >
                    <font-awesome-icon :icon="['fas', 'x']" />
                </button> -->
                <span class="logo">
                    <img
                        loading="lazy"
                        decoding="async"
                        src="@/assets/Images/go_logo.svg"
                        alt="Recruitable"
                        width="150"
                        height="100"
                        class="thelogo cursor-pointer"
                        @click="
                            () => {
                                this.$router.push('/');
                            }
                        "
                    />
                </span>
                <div class="w-full text-left flex flex-col gap-5 mx-[5%] mt-[8%]">
                    <span class="login-span">{{ $t("Register") }}</span>
                    <span class="subLogin">{{ $t("Let's get to know you better") }}</span>
                </div>
                <div class="form-section-container">
                    <div class="w-[80%] mx-auto flex flex-row items-center justify-between relative mt-8 mb-20">
                        <span
                            v-for="(step, index) in steps"
                            :key="index"
                            class="relative text-white w-10 h-10 rounded-full flex justify-center items-center before:absolute before:bg-transparent before:border-2 before:border-white before:w-8 before:h-8 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full"
                            :class="`after:absolute after:-bottom-8 after:left-1/2 after:-translate-x-1/2 after:text-slate-700 after:content-['${step.title}'] ${
                                this.step >= step.number ? 'bg-[#2371b6]' : 'bg-[#A7C6E3]'
                            } `"
                            @click="jumpTostep(step.number)"
                            >{{ step.number < this.step ? "&#10004;" : step.number }}
                            <h1 class="absolute -bottom-8 left-1/2 -translate-x-1/2 text-sm font-bold text-slate-700 w-fit whitespace-nowrap overflow-visible text-center">
                                {{ step.title }}
                            </h1>
                        </span>
                        <span
                            v-for="(step, index) in steps"
                            :key="index"
                            class="absolute h-0.5 top-1/2 -translate-y-1/2"
                            :class="index == steps.length - 1 ? 'hidden' : '' + ' ' + this.step > step.number ? 'bg-[#2371b6]' : 'bg-[#A7C6E3]'"
                            :style="{ width: `${70}%`, left: `${(lineWidth() + 8) * index + 15}%` }"
                        ></span>
                    </div>
                    <!-- Section 2 -->
                    <div class="form_section" v-show="section === 1">
                        <!-- <span id="company">1. {{ $t("Company Information") }}</span> -->
                        <form action="POST" @submit.prevent="onSubmit" ref="form1">
                            <div class="input_group2 duration-300" :class="requiredFields.company_name || requiredFields.employees_count ? 'mb-6' : ''">
                                <div class="input_group">
                                    <input type="text" id="company_name" name="company_name" v-model="company.name" required />
                                    <label for="company_name" class="required floating_label" :class="{ active: company.name }"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/name_icon.svg" alt="" /> {{ $t("Company Name") }}</label
                                    >
                                    <span v-if="requiredFields.company_name" class="err_msg">{{ requiredFields.company_name }}</span>
                                </div>
                                <div class="input_group select">
                                    <label class="required select_label" for="employees_count"><img loading="lazy" decoding="async" src="@/assets/Images/icons/user_icon.svg" alt="" /></label>
                                    <VueMultiselect
                                        :placeholder="$t('select employees count')"
                                        class="skills h-[2.5rem] mt-[2px]"
                                        v-model="company.employees_count"
                                        :options="employees_count"
                                        :taggable="true"
                                        id="employees_count"
                                        name="employees_count"
                                        :allowEmpty="false"
                                    />
                                    <span v-if="requiredFields.employees_count" class="err_msg">{{ requiredFields.employees_count }}</span>
                                </div>
                            </div>

                            <div class="input_group2 duration-300" :class="requiredFields.company_website || requiredFields.company_industry ? 'mb-6' : ''">
                                <div class="input_group">
                                    <input type="text" id="company_website" name="company_website" v-model="company.website" required />
                                    <label class="required floating_label" :class="{ active: company.website }" for="company_website"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/web_icon.svg" alt="" />{{ $t("Company Website") }}</label
                                    >
                                    <span v-if="requiredFields.company_website" class="err_msg">{{ requiredFields.company_website }}</span>
                                </div>
                                <div class="input_group select">
                                    <label class="required select_label" for="company_industry"><img loading="lazy" decoding="async" src="@/assets/Images/icons/industry_icon.svg" alt="" /></label>
                                    <VueMultiselect
                                        :placeholder="$t('select company\'s industry')"
                                        class="skills h-[2.5rem] mt-[2px]"
                                        v-model="company.industry"
                                        :options="industries"
                                        :taggable="true"
                                        id="company_industry"
                                        :allowEmpty="false"
                                        name="company_industry"
                                    />
                                    <span v-if="requiredFields.company_industry" class="err_msg">{{ requiredFields.company_industry }}</span>
                                </div>
                            </div>

                            <div class="input_group2 duration-300" :class="requiredFields.company_logo ? 'mb-6' : ''">
                                <div class="input_group">
                                    <label class="upload_pic" for="company_logo">
                                        <span class="required">{{ $t("Company Logo") }}</span>
                                        <div>
                                            <div class="bg-[#2196f3] flex justify-center items-center" style="width: 3rem">
                                                <img loading="lazy" decoding="async" src="@/assets/icon_upload.svg" />
                                            </div>
                                            <div class="flex gap-2 ml-4 items-center">
                                                <img loading="lazy" style="width: 16px" decoding="async" v-if="logo" src="@/assets/Images/icons/check-green.svg" /><span class="mr-2">{{
                                                    logo.name ? logo.name : "Select logo"
                                                }}</span>
                                            </div>
                                        </div>
                                        <span>*{{ $t("Company Logo should be a PNG file of 250 x 250 pixels") }}.</span>
                                        <div class="w-full flex justify-center"></div>
                                    </label>
                                    <input
                                        type="file"
                                        name="company_logo"
                                        id="company_logo"
                                        required
                                        @change="
                                            (e) => {
                                                if (e.target.files[0]?.type == 'image/png') {
                                                    if (e.target.files[0].size > 1000000) {
                                                        requiredFields.company_logo = 'please upload a logo with smaller size';
                                                    } else {
                                                        requiredFields.company_logo = '';
                                                        logo = e.target.files[0];
                                                    }
                                                } else requiredFields.company_logo = 'Please upload a PNG file';
                                            }
                                        "
                                        accept=".png"
                                    />
                                    <span v-if="requiredFields.company_logo" class="err_msg">{{ requiredFields.company_logo }}</span>
                                </div>
                            </div>
                        </form>
                        <hr class="h-[5px] bg-[#F5F8FF] w-full my-10" />
                    </div>

                    <!-- Section 3 -->
                    <div class="form_section" v-show="section === 2">
                        <!-- <span id="recruiter">2. {{ $t("Recruiter Information") }} </span> -->
                        <form action="POST" @submit.prevent ref="form2">
                            <!-- ... your form content for section 3 ... -->
                            <div class="input_group2 duration-300" :class="requiredFields.first_name || requiredFields.last_name ? 'mb-6' : ''">
                                <div class="input_group">
                                    <input type="text" id="first_name" v-model="recruiter.first_name" required name="first_name" />
                                    <label
                                        class="required floating_label"
                                        :class="{
                                            active: recruiter.first_name,
                                        }"
                                        for="first_name"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/user_icon.svg" alt="" />{{ $t("First Name") }}</label
                                    >
                                    <span v-if="requiredFields.first_name" class="err_msg">{{ requiredFields.first_name }}</span>
                                </div>
                                <div class="input_group">
                                    <input type="text" id="last_name" name="last_name" required v-model="recruiter.last_name" />
                                    <label class="required floating_label" :class="{ active: recruiter.last_name }" for="last_name"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/user_icon.svg" alt="" /> {{ $t("Last name") }}</label
                                    >
                                    <span v-if="requiredFields.last_name" class="err_msg">{{ requiredFields.last_name }}</span>
                                </div>
                            </div>
                            <div class="input_group2 duration-300" :class="requiredFields.recruiter_email || requiredFields.telephone ? 'mb-6' : ''">
                                <div class="input_group">
                                    <input type="email" id="recruiter_email" name="recruiter_email" required v-model="recruiter.email" />
                                    <label class="required floating_label" :class="{ active: recruiter.email }" for="recruiter_email"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/email.svg" alt="" />Email</label
                                    >
                                    <span v-if="requiredFields.recruiter_email" class="err_msg">{{ requiredFields.recruiter_email }}</span>
                                </div>
                                <div class="input_group select">
                                    <label class="required select_label" for="phone_number"><img loading="lazy" decoding="async" src="@/assets/Images/icons/icon_Phone.svg" alt="" /></label>
                                    +{{ this.dialCode }}
                                    <vue-tel-input @input="handlePhoneInput" @country-changed="handleCountryChange" class="phone" name="telephone"> </vue-tel-input>
                                    <span v-if="requiredFields.telephone" class="err_msg">{{ requiredFields.telephone }}</span>
                                </div>
                            </div>
                            <div class="input_group2 duration-300" :class="requiredFields.recruiter_password || requiredFields.confirm_password ? 'mb-6' : ''">
                                <div class="input_group">
                                    <input :type="showPassword ? 'text' : 'password'" id="recruiter_password" name="recruiter_password" required v-model="recruiter.password" />
                                    <button class="showpassword" @click.prevent="showPassword = !showPassword">
                                        <img
                                            loading="lazy"
                                            decoding="async"
                                            class="eye-icon"
                                            :src="require(`@/assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                            width="25"
                                            height="25"
                                            alt=""
                                        />
                                    </button>
                                    <label class="required floating_label" :class="{ active: recruiter.password }" for="password"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/lock.svg" alt="" />Password</label
                                    >
                                    <span v-if="requiredFields.recruiter_password" class="err_msg">{{ requiredFields.recruiter_password }}</span>
                                </div>
                                <div class="input_group">
                                    <input :type="showConfirmPassword ? 'text' : 'password'" id="confirm_password" name="confirm_password" required v-model="recruiter.confirm_password" />
                                    <button class="showpassword" @click.prevent="showConfirmPassword = !showConfirmPassword">
                                        <img loading="lazy" class="eye-icon" :src="require(`@/assets/${showConfirmPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                                    </button>

                                    <label
                                        class="required floating_label"
                                        :class="{
                                            active: recruiter.confirm_password,
                                        }"
                                        for="confirm_password"
                                        ><img loading="lazy" decoding="async" src="@/assets/Images/icons/lock.svg" alt="" />{{ $t("Confirm password") }}</label
                                    >
                                    <span v-if="requiredFields.confirm_password" class="err_msg">{{ requiredFields.confirm_password }}</span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Section 4 -->
                    <!-- <div class="form_section" v-show="section === 3">
                        <span id="featured">3. {{ $t("Featured Jobs") }}</span>
                        <span>{{ $t("If you purchased a featured job, please insert your order ID below") }}.</span>
                        <form action=""> 

                            <div class="input_group">
                                <input type="text" id="order_id" v-model="discount" />
                                <label class="floating_label" :class="{ active: discount }" for="order_id">{{ $t("Discounts") }}</label>
                            </div>
                        </form>
                    </div> -->
                    <div class="mt-10" v-if="section == 2">
                        <div class="input_group2 my-4">
                            <div class="input_group w-1/2">
                                <input type="text" id="order_id" v-model="discount" />
                                <label class="floating_label" :class="{ active: discount }" for="order_id">{{ $t("Discounts") }}</label>
                            </div>
                            <div></div>
                        </div>
                        <span class="subLogin"
                            >{{ $t("By clicking submit, you agree to our") }} <a href="/terms" class="blue_span">{{ $t("Terms of Service") }}</a> {{ $t("and") }}
                            <a href="/privacy" class="blue_span">{{ $t("Privacy Policy") }}</a></span
                        >
                        <hr class="h-[5px] bg-[#F5F8FF] w-full mb-10" />
                    </div>

                    <div class="btn_wrapper">
                        <button class="previous" @click="previousSection" :disabled="section === 1">{{ $t("Previous") }}</button>
                        <button v-if="section !== 2" class="next" @click="nextSection" :disabled="section === 2">{{ $t("Next") }}</button>
                        <div v-else class="w-[50%]">
                            <button v-if="!loading" type="submit" value="Log in" class="loginBtn" @click="nextSection()">{{ $t("Submit") }}</button>
                            <div class="loginBtn" v-else>
                                <LoaderComponent />
                            </div>
                        </div>
                        <span v-if="this.message" class="err_msg err2">{{ this.message }} </span>
                    </div>
                    <div class="w-full text-center">
                        <span class="sign_up">
                            {{ $t("Already have an account?") }}
                            <router-link to="/login" @click="toggleModal">{{ $t("Sign In") }}</router-link>
                        </span>
                    </div>
                </div>
            </div>
            <div class="box imgContainer" v-if="this.screenWidth > 768">
                <img v-if="!imgLoaded" class="h-full absolute z-[10] top-0 bottom-0 right-0" src="@/assets/landing-page-logos/lowSizeRegisterImg.png" alt="" />

                <img
                    class="h-full absolute top-0 bottom-0 right-0"
                    src="@/assets/landing-page-logos/registerImg.png"
                    @load="
                        () => {
                            this.imgLoaded = true;
                        }
                    "
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import "@/assets/styles/vue-multiselect.css";
import axios from "axios";
import ToastNotification from "@/components/ToastNotification";
import { useStore } from "@/store/index";
import LoaderComponent from "@/components/LoaderComponent.vue";
import ConfirmEmail from "@/components/ConfirmEmail.vue";
export default {
    name: "RegisterPage",
    components: { VueMultiselect, ToastNotification, LoaderComponent, ConfirmEmail },
    watch: {
        phone() {},
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            showPassword: false,
            showConfirmPassword: false,
            showConfirm: false,
            loading: false,
            nextStep: false,
            screenWidth: window.innerWidth,
            section: 1,
            isFixed: false,
            initialPosition: 500,
            discount: "",
            logo: "",
            cover: "",
            isVisible: false,
            message: "",
            step: 1,
            steps: [
                { number: 1, title: "Company Information" },
                { number: 2, title: "Recruiter Information" },
            ],
            job: {
                title: "",
                seniority: "",
                compensation: "",
                department: "",
                skills: [],
                salary: null,
                description: "",
                hide_salary: false,
                company_name: "",
            },
            company: {
                name: "",
                website: "",
                industry: "",
                logo: "",
                cover: "",
                description: "",
                facebook: "",
                linkedin: "",
                instagram: "",
                twitter: "",
                employees_count: "",
            },
            recruiter: {
                first_name: "",
                last_name: "",
                email: "",
                phone_nbr: "",
                password: "",
                confirm_password: "",
                company_name: [],
            },
            skills: ["communication", "team work", "Python", "SQL", "Java", "Javascript"],
            seniorities: ["Internship", "Entry Level", "Junior", "Senior", "VP", "Executive"],
            departments: [
                "Executive - CEO, COO, CFO",
                "Finance - Accounting, Budgeting, Payroll",
                "Human Resources - Recruiting, Training, Compensation, Benefits",
                "Sales - Sales Development, Account Management, Customer Success",
                "Marketing - Branding, Advertising, PR, Digital Marketing",
                "Customer Service - Support, Complaint Resolution, Retention",
                "Operations - Manufacturing, Supply Chain, Logistics, Production",
                "Research & Development - Product Development, Quality Assurance, Testing",
                "Information Technology - Systems, Software, Infrastructure, Security",
                "Legal - Contracts, Compliance, Intellectual Property",
                "Administrative - Office Management, Facilities, Reception",
                "Engineering - Product, Software, Systems, Quality",
                "Design - Graphic, User Experience, User Interface, Industrial",
                "Business Development - Strategy, Partnerships, Investor Relations",
                "Project/Program Management - Project Planning, Launching Initiatives",
                "Data/Analytics - Business Intelligence, Data Science, Reporting",
                "Risk Management - Assessing, Monitoring, Mitigating Business Risks",
                "Corporate Communications - Public Relations, Reputation Management",
            ],
            compensations: ["Entry-level: $12 - $16 per hour", "Skilled/Experienced: $17 - $25 per hour", "Manager/Supervisor: $26 - $35 per hour", "Executive/Director: $36 - $50 per hour"],
            employees_count: ["1-10", "11-50", "51-100", "+100"],
            industries: [
                "3D Printing",
                "5G",
                "Adtech",
                "Agritech",
                "Artificial Intelligence",
                "Autonomous Vehicles",
                "Banking",
                "Biotech",
                "Blockchain",
                "Clean Tech",
                "Cloud Computing",
                "Computer Hardware",
                "Cryptocurrency",
                "Cybersecurity",
                "Cybersecurity Tech",
                "Data Analytics",
                "E-Commerce",
                "Edtech",
                "Enterprise Software",
                "Fintech",
                "Food Tech",
                "Gamification",
                "Govtech",
                "Healthtech",
                "HR Tech",
                "Insurtech",
                "Internet of Things",
                "IT Services",
                "Legaltech",
                "Machine Learning",
                "Martech",
                "Medtech",
                "Mobile Apps",
                "Oil & Gas",
                "Pharmaceuticals",
                "Proptech",
                "Quantum Computing",
                "Remote Work Tech",
                "Ridesharing Tech",
                "Robotics",
                "SaaS",
                "Space Tech",
                "Streaming Tech",
                "Supply Chain Tech",
                "Travel Tech",
                "Virtual Reality",
                "Wireless Tech",
                "other",
            ],
            requiredFields: {
                // job_title: "",
                // job_seniority: "",
                // job_department: "",
                // job_skills: "",
                // job_compensation: "",
                // job_description: "",
                // company_name: "",
                // company_website: "",
                // company_industry: "",
                // company_description: "",
                // company_logo: "",
                // company_cover: "",
                // employees_count: "",
            },
            job_skills: [],
            job_seniority: "",
            job_compensation: "",
            job_department: "",
            dialCode: "",
            selectedPlan: "",
            imgLoaded: false,
        };
    },
    mounted() {
        let code = localStorage.getItem("ref");
        this.discount = code ? code : "";
        this.selectedPlan = this.$route.query.plan;
        window.addEventListener("scroll", this.handleScroll);
        this.handleCountryChange();
    },

    methods: {
        handlePhoneInput(newPhone, newCountry) {
            if (newCountry) {
                this.recruiter.phone_nbr = newCountry?.number;
            }
        },
        toggleConfirmEmail() {
            this.showConfirm = !this.showConfirm;
        },
        handleCountryChange(newCountry) {
            // this.country = newCountry; // Update the country data property
            this.dialCode = newCountry?.dialCode;
        },

        form_section(/* section */) {
            // this.section = section;
        },
        handleScroll() {},
        sendContactUs(email, name) {
            this.loading = true;
            let contactForm = {
                email: email,
                name: name,
                plan: this.selectedPlan,
            };
            axios
                .post("https://server.go-platform.com/requestPlan", contactForm, {
                    withCredentials: true,
                })
                .then(() => {
                    this.step = 2;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.selectedPlan = "";
                });
        },
        async submitForms() {
            this.loading = true;
            this.sendContactUs(this.recruiter.email, `${this.recruiter.first_name} - ${this.recruiter.last_name}`);
            this.job.company_name = this.company.name;
            this.recruiter.company_name.push(this.company.name);
            var formdata = new FormData();
            const companyJSON = JSON.stringify(this.company);
            const recruiterJSON = JSON.stringify(this.recruiter);
            formdata.append("company", companyJSON);
            formdata.append("discount", this.discount);
            formdata.append("recruiter", recruiterJSON);
            // formdata.append("job", JSON.stringify(this.job));
            formdata.append("logo", this.logo);
            formdata.append("cover", this.cover);
            var requestOptions = {
                method: "POST",
                body: formdata,
                credentials: "include",
                redirect: "follow",
            };
            try {
                fetch("https://server.go-platform.com/company/register", requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        this.loading = false;
                        result = JSON.parse(result);
                        if (result.msg) {
                            this.message = result.msg;
                            return;
                        }
                        if (result.email) {
                            this.requiredFields.recruiter_email = result.email;

                            return;
                        }
                        if (result.phone) {
                            this.requiredFields.telephone = result.phone;

                            return;
                        }
                        if (result.password) {
                            this.requiredFields.confirm_password = result.password;
                            return;
                        }

                        // this.Store.userLogged();
                        // this.Store.getCompanyCredit();
                        // console.log("REDIRECTING TO DASHBOARD", result.company_name);
                        // this.$router.push(`/dashboard?company_name=${result.company_name}`);
                        // this.Store.isLoading = true;
                        this.showConfirm = true;
                        this.job = {
                            title: "",
                            seniority: "",
                            compensation: "",
                            department: "",
                            skills: [],
                            salary: null,
                            description: "",
                            hide_salary: false,
                            company_name: "",
                        };
                        this.logo = "";
                        this.cover = "";
                        this.company = {
                            name: "",
                            website: "",
                            industry: "",
                            description: "",
                            facebook: "",
                            linkedin: "",
                            instagram: "",
                            twitter: "",
                        };
                        this.recruiter = {
                            first_name: "",
                            last_name: "",
                            email: "",
                            phone_nbr: "",
                            password: "",
                            confirm_password: "",
                            company_name: [],
                        };
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.log({ errorMsg: error });
                    });
            } catch (error) {
                console.error(error);
            }
        },
        lineWidth() {
            return 100 / (this.steps.length - 1) - 10;
        },
        checkSubmit() {
            if (!this.$refs.form1.checkValidity() || !this.$refs.form2.checkValidity()) {
                this.requiredFields = {
                    job_title: "",
                    job_department: "",
                    job_seniority: "",
                    job_skills: "",
                    job_compensation: "",
                    job_description: "",
                    company_name: "",
                    company_website: "",
                    company_industry: "",
                    company_description: "",
                    company_cover: "",
                    company_logo: "",
                };
                for (let i = 0; i < this.$refs.form1.elements.length; i++) {
                    const input = this.$refs.form1.elements[i];
                    if ((input.nodeName === "INPUT" || input.nodeName === "TEXTAREA") && !input.checkValidity()) {
                        this.requiredFields[input.name] = input.validationMessage;
                    }
                }
                for (let i = 0; i < this.$refs.form2.elements.length; i++) {
                    const input = this.$refs.form2.elements[i];
                    if ((input.nodeName === "INPUT" || input.nodeName === "TEXTAREA") && !input.checkValidity()) {
                        this.requiredFields[input.name] = input.validationMessage;
                        if (input.name === "email") {
                            // Email validation logic (as mentioned before)
                        }

                        if (input.name === "job_description" || input.name === "company_description") {
                            // Description length validation logic (as mentioned before)
                        }

                        // Password validation
                        if (input.name === "password") {
                            const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
                            if (!passwordRegex.test(input.value)) {
                                this.requiredFields[input.name] = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
                            }
                        }
                    }
                }
            } else {
                if (this.section === 2) {
                    this.submitForms();
                }
                this.nextStep = true;
            }
        },
        nextSection() {
            // this.checkSubmit()
            const form = `form${this.section}`;
            this.checkForm(form);

            if (this.section < 4 && this.nextStep) {
                this.section++;
                document.documentElement.scrollTop = 0;
                this.nextStep = false;
                this.step = this.step + 1;
            }
        },
        previousSection() {
            if (this.section > 1) {
                this.section--;
                document.documentElement.scrollTop = 0;
            }
            this.step = this.step - 1;
        },
        checkForm(form) {
            this.requiredFields = {
                job_title: "",
                job_department: "",
                job_seniority: "",
                job_skills: "",
                job_compensation: "",
                job_description: "",
                company_name: "",
                company_website: "",
                company_industry: "",
                company_description: "",
                company_cover: "",
                company_logo: "",
                telephone: "",
                recruiter_password: "", // New field for password validation
            };

            let formValid = true; // Flag to track form validity

            for (let i = 0; i < this.$refs[form].elements.length; i++) {
                const input = this.$refs[form].elements[i];

                if (input.nodeName === "INPUT" || input.nodeName === "TEXTAREA") {
                    // Additional validations for specific fields
                    if (input.name === "recruiter_email") {
                        const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

                        if (!emailRegex.test(input.value)) {
                            this.requiredFields[input.name] = "Please enter a valid email address.";
                            formValid = false; // Update the formValid flag
                        }
                    }

                    if (input.name === "company_website") {
                        const websiteRegex = /\./; // Check for the presence of a dot ('.')
                        if (!websiteRegex.test(input.value)) {
                            this.requiredFields[input.name] = "Website must contain a dot (.) in the URL.";
                            formValid = false; // Update the formValid flag
                        }
                    }

                    // Password validation
                    if (input.name === "recruiter_password") {
                        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
                        if (!passwordRegex.test(input.value)) {
                            this.requiredFields[input.name] = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
                            formValid = false; // Update the formValid flag
                        }
                    }
                    if (
                        input.name !== "email" &&
                        input.name !== "company_website" &&
                        input.name !== "recruiter_password" &&
                        input.name !== "employees_count" &&
                        input.name !== "company_industry" &&
                        input.name !== "telephone"
                    ) {
                        if (input.value.trim() === "") {
                            this.requiredFields[input.name] = "This field is required.";
                            formValid = false; // Update the formValid flag
                        }
                    }
                    if (input.name === "employees_count" && !this.company.employees_count) {
                        this.requiredFields[input.name] = "Please select employees count.";
                        formValid = false; // Update the formValid flag
                    }
                    if (input.name === "company_industry" && !this.company.industry) {
                        this.requiredFields[input.name] = "Please select company industry.";
                        formValid = false; // Update the formValid flag
                    }
                    if (input.name === "telephone") {
                        // if (!this.recruiter.phone_nbr) {
                        //     const phoneWithoutDialCode = this.recruiter.phone_nbr.replace("+" + this.dialCode, ""); // Remove the dial code

                        //     const phoneNumberRegex = /^\d{9}$/; // Example: Regular expression for a 10-digit phone number

                        //     // Check if the phone number without the dial code matches the 10-digit format
                        //     if (!phoneNumberRegex.test(phoneWithoutDialCode.replace(/\D/g, ""))) {
                        //         this.requiredFields.telephone = "Please enter a valid 10-digit phone number.";
                        //         formValid = false; // Update the formValid flag
                        //     }
                        // } else {
                        //     this.requiredFields.telephone = "Phone number is required.";
                        //     formValid = false; // Update the formValid flag
                        // }
                        if (!this.recruiter.phone_nbr) {
                            this.requiredFields.telephone = "Phone number is required.";
                            formValid = false; // Update the formValid flag
                        } else if (/[a-zA-Z]/.test(this.recruiter.phone_nbr)) {
                            this.requiredFields.telephone = "Phone number cannot contain letters.";
                            formValid = false; // Update the formValid flag
                        }
                    }
                }
            }

            if (formValid) {
                if (this.section === 2) {
                    this.submitForms();
                } else this.nextStep = true;
            }
        },
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped lang="scss">
@import "../../../assets/styles/vue-multiselect.css";

.register_page {
    // display: grid;
    // grid-template-columns: 2.5fr 1fr;
    // grid-template-areas:
    //     "tabs talent "
    //     "form  talent"
    //     "form . "
    //     "form .";
    display: flex;
    // padding: 3rem;
    // gap: 2rem 3rem;
    height: fit-content;
    margin-bottom: 10%;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
    }

    .err_msg {
        color: #ff6969;
        font-size: 12px;
        font-weight: 300;
        position: absolute;
        top: 100%;
    }

    .err2 {
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        text-decoration: none !important;
    }

    .blue_span {
        color: #2196f3;
    }

    .fa-icon {
        font-size: 28px;
        font-weight: 800;
        /* color: #A3AED0; */
    }

    .input_group {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        // padding: 0 1rem;
        border-radius: 10px;
        width: 100%;
        font-size: 14px;

        label {
            display: flex;
            align-items: center;
            font-weight: 500;
            line-height: 32px;
            color: #05152e;
            padding: 0;
            font-size: 14px;

            img {
                margin-right: 5px;
            }
        }

        .required {
            &::after {
                content: "*";
                color: #ff6969;
            }
        }

        .floating_label {
            position: absolute;
            top: 50%;
            left: 0;
            margin-left: 15px;
            font-size: 14px;
            padding: 0 1% 0 0;
            transform: translateY(-50%);
            pointer-events: none;
            background: #fff;
            transition: all 0.3s ease;
        }

        .floating_label.active {
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
            border-radius: 50px;
            // width: 10%;
            background: #fff;
            font-size: 12px;
            transform: translateY(-120%);
        }

        input:focus + .floating_label {
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
            border-radius: 50px;
            // width: 10%;
            background: #fff;
            font-size: 12px;
            transform: translateY(-120%);
        }

        input,
        textarea {
            border: 1px solid #ccd7ec;
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
            border-radius: 10px;
            width: 100%;
            padding: 0 1.5rem;
            color: #05152e;
        }

        input {
            height: 2.5rem;
        }

        textarea {
            resize: none;
            padding-top: 1rem;
        }

        input[type="file"] {
            display: none;
        }

        .phone {
            height: 100%;
            border-radius: 10px;
            padding: 2%;

            & > :nth-child(2) {
                // border: 2px solid red;
                border-radius: 10px;
            }
        }
    }

    .select {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        border: 1px solid #ccd7ec;
        padding: 0.5% 0 0.5% 1%;
        height: 2.5rem;

        > :nth-child(2) {
            width: 100%;
            float: right;
            right: 0;
            // border: none;
        }

        .select_label {
            // position: absolute;
            // top: 50%;
            // left: 5%;
            // z-index: 5;
            padding: 0 1% 0 0;
            // transform: translateY(-50%);
            pointer-events: none;
            background: #fff;
            transition: all 0.3s ease;
        }
    }

    .input_group2 {
        display: flex;
        justify-content: space-between;
        // margin-top: 2%;
        gap: 2rem;
        width: 100%;

        div {
            width: 100%;
        }

        .skills {
            border-radius: 10px;

            > :nth-child(2) {
                border: none;
                padding: 0;
                height: 10%;

                > :nth-child(2) {
                    padding: 0;
                    border: 2px solid red;
                }
            }

            > :nth-child(4) {
                // border: none;
                margin: 0;
            }
        }

        input,
        textarea {
            border: 1px solid #ccd7ec;
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
            border-radius: 10px;
            width: 99%;
            padding: 0 0 0 1.5rem;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */

            flex-direction: column;
            align-items: center;

            div {
                width: 90%;
            }

            .skills {
                width: 100%;
            }
        }
    }

    .upload_pic {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1rem;

        > :first-child {
            width: 100%;
            text-align: left;
        }

        > :nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            // background: #2196f3;
            width: 100%;
            height: 3rem;
            border-radius: 10px;
            border: 1px solid #ccd7ec;
            overflow: hidden;

            img {
                height: 2rem;
                width: 2rem;
                margin: 0;
                // padding: 5px;
                box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
                display: flex;
                flex-direction: column;
            }
        }

        > :nth-child(3) {
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #858c95;
        }
    }

    .hide_salary {
        display: flex;
        flex-wrap: wrap;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #05152e;
        gap: 0.5rem;
        padding: 0 1rem;

        > :last-child {
            flex-basis: 100%;
            line-height: 32px;
            color: #47505c;
            padding: 0 1rem;
        }
    }

    .box {
        background: #fff;
        border-radius: 22px;
        // box-shadow: 0px 2px 8px rgba(30, 54, 170, 0.08);
        text-align: left;
        padding: 1rem 2rem;

        &:nth-child(2) {
            @media (min-width: 768px) and (max-width: 991.98px) {
                padding: 1rem 0.5rem;
            }
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 92%;
            // padding: 1rem 1rem;
        }
    }

    .imgContainer {
        width: 40%;
        height: 100vh;
        border-radius: 0;
    }

    .form_tabs {
        grid-area: tabs;
        display: flex;
        flex-direction: column;
        // gap: 1rem;
        position: relative;
        transition: position 1s ease-in-out;
        // box-shadow: 0px 0px 0px 0px rgba(71, 80, 92, 0.75);
        // -webkit-box-shadow: 0px 0px 0px 0px rgba(71, 80, 92, 0.75);
        // -moz-box-shadow: 0px 0px 0px 0px rgba(71, 80, 92, 0.75);

        > :first-child {
            font-weight: 400;
            font-size: 22px;
            line-height: 24px;
            color: #05152e;
        }

        > :nth-child(2) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            //gap: 2rem;

            span {
                border-radius: 25px;
                padding: 1rem;
                cursor: pointer;
                position: relative;

                // &::after {
                //     content: "";
                //     position: absolute;
                //     top: 50%;
                //     left: 100%;
                //     width: 90%;
                //     height: 3px;
                //     background-color: #2196f3;
                //     transform: translateY(-50%);
                // }
                @media (min-width: 768px) and (max-width: 991.98px) {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    font-size: 16px;
                    padding: 1rem 0.75rem;
                }

                @media (max-width: 991px) {
                    /* Your CSS styles for smartphones go here */
                    &::after {
                        display: none;
                    }
                }
            }

            span:focus {
                background: #2196f3 !important;
                color: #fff;
            }

            > :last-child {
                &::after {
                    // display: none;
                }
            }
        }

        .form_section {
            width: 90%;
            text-decoration: none;
            // font-weight: 400;
            font-size: 24px;
            line-height: 20px;
            // color: #858c95;
            margin: 0 5%;
        }

        .current {
            // background: #2196f3;
            a {
                // color: #fff;
                font-weight: 700;
            }
        }

        .form_tabs_items {
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 2rem;

            @media (min-width: 768px) and (max-width: 991.98px) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 0;
            }
        }
    }

    .form {
        grid-area: form;
        display: flex;
        // gap: 2rem;
        flex-direction: column;
        // align-items: center;
        height: fit-content;
        width: 55%;
        height: 100%;
        text-align: left;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        // box-shadow: 0px 0px 20px -5px rgba(71, 80, 92, 0.75);
        // -webkit-box-shadow: 0px 0px 20px -5px rgba(71, 80, 92, 0.75);
        // -moz-box-shadow: 0px 0px 20px -5px rgba(71, 80, 92, 0.75);

        .form_section {
            display: flex;
            flex-direction: column;
            // gap: 2rem;
            width: 100%;

            > :first-child {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                // color: #2196f3;
            }

            form {
                display: flex;
                flex-direction: column;
                gap: 1.2rem;

                // &::after {
                //     content: "";
                //     width: 100%;
                //     height: 1px;
                //     background: #e5e5ef;
                //     margin: 1rem 0;
                // }
            }
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            //   grid-column: span 2;
        }

        @media (max-width: 768px) {
            /* Your CSS styles for smartphones go here */
            width: 100%;

            > :nth-child(2) {
                margin-top: 15%;
            }
        }
    }

    input[type="submit"] {
        background-color: #2196f3;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        font-weight: 500;
        font-size: 24px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #fff;
            // color: #2196f3;
            border: 1px solid #2196f3;
            cursor: pointer;
        }
    }

    .fixed {
        position: fixed;
        width: 20rem;
        top: 1rem;
    }
}

.form-section-container {
    height: fit-content;
    width: 80%;
    margin: 0% 10%;
    z-index: 10;

    .previous {
        height: 45px;
        width: 150px;
        background-color: #fff;
        color: #2196f3;
        border: 1px solid #2196f3;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #2196f3;
            color: #fff;
            border: 1px solid #fff;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }

    .next {
        height: 45px;
        width: 150px;
        background-color: #2196f3;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        font-weight: 600;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #fff;
            color: #2196f3;
            border: 1px solid #2196f3;
            cursor: pointer;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }

    .sign_up {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #4a5568;
        font-family: "Roboto";
        width: 100%;

        a {
            text-decoration: none;
            color: #2196f3;
        }
    }

    @media (max-width: 768px) {
        /* Your CSS styles for smartphones go here */
        width: 100%;
        margin: 0 3%;
    }
}

.btn_wrapper {
    height: 80px;
    width: 50%;
    margin: 0 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    gap: 8px;
    bottom: 0%;
}

.loginBtn {
    cursor: pointer;
    transition: 0.2s ease-in;
    background-color: #2196f3;
    color: white;
    height: 45px;
    width: 150px;
    padding: 12px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto";

    &:hover {
        opacity: 0.85;
    }
}

.login-span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    color: #263238;
    text-align: left;
    font-size: 28px;
    margin-bottom: 0;
}

.subLogin {
    color: #969696;
    font-size: 14px;
    text-align: left;
}

.showpassword {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
}

.logo {
    font-weight: 900;
    font-size: 40px;
    line-height: 18px;
    // color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1rem 0 3rem;
    gap: 0.5rem;
    position: absolute;
    top: 1%;
    left: 5%;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
    }
}

.form::-webkit-scrollbar {
    width: 5px;
    /* Width of the scrollbar */
}

.form::-webkit-scrollbar-track {
    // background-color: #2195f328;
    /* Color of the scrollbar track */
    border-radius: 5px;
    /* Rounded corners */
}

.form::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.form-section-container::-webkit-scrollbar-thumb:hover {
    // background-color: #2195f328;
    /* Color of the scrollbar thumb on hover */
}
</style>
